import {MutableRefObject} from 'react';

export function getParallaxPos(node: MutableRefObject<HTMLDivElement | null>, offset: number, speed: number): number {
  let newOffset = 0;
  if (node && node.current) {
    let containerOffsetTop = node.current.offsetTop;
    let relativeScrollOffset = offset - containerOffsetTop;
    newOffset = relativeScrollOffset * speed / 100;
  }
  return newOffset;
}

export function getParallaxHeaderPos(offset: number, speed: number):number {
  return offset * speed / 100;
}
