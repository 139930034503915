import React, {useEffect, useRef, useState} from 'react';
import './Footer.scss';
import headerImage from '../../res/imgs/img_photo-tavolata.jpg';
import {ReactComponent as ChickenImg} from '../../res/imgs/img_anim-chicken.svg';
import {ReactComponent as OnionImg} from '../../res/imgs/img_dec-onion.svg';
import {getParallaxPos} from '../../services/parallaxService';

export const Footer: React.FC = () => {
  const Link: React.FunctionComponent<{ url: string, title: string }> = ({url, title}) => {
    return <div><a target="_blank" rel="noopener noreferrer" href={url}>{title}</a> unseres Vertrauens</div>;
  };

  const [offset, setOffset] = useState(0);
  const parallaxBoxRef = useRef<HTMLDivElement>(null);
  const gratitudeContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return (
      () => window.removeEventListener('scroll', handleScroll)
    );
  }, []);

  return (
    <div className={`Footer ${window.innerWidth/window.innerHeight > 1 ? 'desktop-version' : 'mobile-version'}`}>

      <div className={'parallax-box'} ref={parallaxBoxRef}>
        <div className={'contact-container parallax-element'}
             style={{transform: `translateY(${getParallaxPos(parallaxBoxRef, offset, 2)}px)`}}>
          <h2>Kontakt</h2>
          <div>Greyerzstrasse 61 – 3013 Bern</div>
          <a href="mailto:tavolata@innere-werte.ch">tavolata@innere-werte.ch</a>
        </div>

        <div className={'image-container parallax-element'}>
          <img src={headerImage} alt={'Bild der Tavolata'}
               style={{transform: `translateY(${getParallaxPos(parallaxBoxRef, offset, -4)}px)`}}/>
        </div>

        <ChickenImg className={'chicken-img'}
                    style={{transform: `translateY(${getParallaxPos(parallaxBoxRef, offset, 18)}px)`}}/>
        <OnionImg className={'onion-img'}
                  style={{transform: `translateY(${getParallaxPos(parallaxBoxRef, offset, 4)}px)`}}/>
      </div>

      <div className={'gratitude-container parallax-element'} ref={gratitudeContainerRef}
           style={{transform: `translateY(${getParallaxPos(gratitudeContainerRef, offset,  14)}px)`}}>
        <h2>Mit herzlichem Dank</h2>
        <Link url={'http://www.fabianschwander.ch'} title={'Webentwickler'}/>
        <Link url={"http://www.lehmann-s.ch/"} title={'Metzgerei'}/>
        <Link url={"http://www.cascina-fontana.com"} title={'Weinproduzentin'}/>
      </div>

    </div>
  );
};
