import React, {useEffect, useState} from 'react';
import './Header.scss';

import {ReactComponent as TitleImg} from '../../res/imgs/logo-tavolata.svg';
import {ReactComponent as BgLayerImg} from '../../res/imgs/background-layer_bottom.svg';
import {ReactComponent as AnimalCowImg} from '../../res/imgs/img_anim-cow.svg';
import {ReactComponent as AnimalPigImg} from '../../res/imgs/img_anim-pig.svg';

import {ReactComponent as BreadImg} from '../../res/imgs/img_dec-bread.svg';
import {ReactComponent as CarrotImg} from '../../res/imgs/img_dec-carrot.svg';
import {ReactComponent as ParsleyImg} from '../../res/imgs/img_dec-parsley-large.svg';
import {ReactComponent as RosemaryImg} from '../../res/imgs/img_dec-rosemary.svg';

import {ReactComponent as ArrowDown} from '../../res/imgs/icon_arrow-down.svg';
import {getParallaxHeaderPos} from '../../services/parallaxService';

type HeaderProps = {
  offset: number
}

export const Header: React.FC<HeaderProps> = ({offset}) => {
  const [viewMode, setViewMode] = useState();

  useEffect(() => {
    const handleResize = () => {
      setViewMode(window.innerWidth/window.innerHeight > 1 ? 'desktop-version' : 'mobile-version')
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  })

  return (
    <div className={'Header'}>

      <BgLayerImg className={'img-bg-layer_bottom'}
                  style={{transform: `translateY(${0}px) scale(1,${1 + offset / 1500})`}}/>

      <div className={`header-container ${viewMode}`}>
        <TitleImg className={'img-logo'}
                  style={{transform: `translateY(${getParallaxHeaderPos(offset, 16)}px) scale(${1 + offset / 3000})`}}/>

        <div className={'front-container'}>
          <AnimalCowImg className={'img-cow'}
                        style={{transform: `translateX(${getParallaxHeaderPos(offset, -12)}px)`}}/>
          <AnimalPigImg className={'img-pig'}
                        style={{transform: `translateX(${getParallaxHeaderPos(offset, 10)}px)`}}/>
        </div>

        <div className={'decoration-objects'}>
          <RosemaryImg className={'img-rosemary'} style={{transform: `translateX(${offset / 5}px)`}}/>
          <CarrotImg className={'img-carrot'} style={{transform: `translateX(${offset / -5}px)`}}/>
          <BreadImg className={'img-bread'} style={{transform: `translateX(${offset / 10}px)`}}/>
          <ParsleyImg className={'img-parsley'} style={{transform: `translateX(${offset / -10}px)`}}/>
        </div>

        <ArrowDown className={'arrow-down'}
                   style={{opacity: offset < 100 ? 1 : 0}}/>
      </div>
    </div>
  );
};
