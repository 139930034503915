import React, {useEffect, useRef, useState} from "react";
import './Introduction.scss';
import {ReactComponent as GarlicImg} from '../../res/imgs/img_dec-garlic.svg';
import {getParallaxPos} from '../../services/parallaxService';

export const Introduction: React.FC = () => {

  const [offset, setOffset] = useState(0);
  const processContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return (
      () => window.removeEventListener('scroll', handleScroll)
    );
  }, []);

  return (
    <div className={'Introduction'}>

      <div className={'welcome-container'}>
        <p>Dass Fleisch mehr ist als Filet und Entrecôte wissen wir – aber wie schmeckt geräuchertes Rinderherz,
          Kutteln-Ravioli oder Gitzi-Lunge? Weil die Nachfrage fehlt, werden pro Rind ein Fünftel gutes Fleisch
          weggeworfen, das man eigentlich essen könnte. Sinnvoll Fleisch konsumieren bedeutet, ein Tier ganz zu
          verwerten.
          Dabei kommt es nur auf die richtige Zubereitung an.</p>
        <p>An der Tavolata Greyerzstrasse stehen wir für innere Werte und kochen alles – von Kopf bis Fuss.</p>
      </div>

      <div className={'process-container'} ref={processContainerRef}>
        <GarlicImg className={'decoration-garlic'}
                   style={{transform: `translateY(${getParallaxPos(processContainerRef, offset, 6)}px)`}}/>

        <h2>Ablauf</h2>
        <p>Die Tavolata findet jeweils um 19 Uhr an der Greyerzstrasse 61 in 3013 Bern für maximal 12 Personen
          statt.</p>

        <p>Pro Abend wird ein Thema vorgegeben. Aus den genannten Zutaten entstehen drei Gänge, die auf herkömmliche bis
          kreative Art zubereitet werden. Als Abschluss folgt ein nicht-fleischiges Dessert.</p>

        <p>Im Unkostenbeitrag von 75 Franken sind Wein und Wasser inbegriffen. Vegane oder vegetarische Varianten sind
          leider nicht möglich.</p>
      </div>

    </div>
  );
};
