import React from 'react';
import './Content.scss';
import {ReactComponent as BgLayerImg} from '../../res/imgs/background-layer_bottom.svg';

export const Content:React.FC = props => {

  return(
    <div className={'Content'}>
      {props.children}

      <BgLayerImg className={'img-bg-layer_bottom'}/>
    </div>
  )
}
