import React from 'react';
import './FisherAnimation.scss';
import {ReactComponent as FisherImg} from '../../res/imgs/icon_fisher.svg';

export const FisherAnimation: React.FC = () => {
  return (
    <div className={'FisherAnimation'}>
      <FisherImg/>
      <p>Wir machen Zwangspause...</p>
    </div>
  )
};
