import React, {useEffect, useRef, useState} from 'react';
import './Events.scss';
import {FisherAnimation} from '../animations/FisherAnimation';
import {ReactComponent as ParsleyImg} from '../../res/imgs/img_dec-parsley-small.svg';
import {getParallaxPos} from '../../services/parallaxService';

export const Events: React.FC = () => {

  const newsletterAbo = "mailto:tavolata@innere-werte.ch?Subject=Newsletter: Weitere Daten der «Tavolata für innere Werte»&body=Um Informationen über weitere Daten der «Tavolata für innere Werte» zu erhalten, kannst du gerne einfach diese Email abschicken. Sobald wir die nächsten Daten und Zutaten festgelegt haben, werden wir dich gerne informieren.%0A%0ADeine E-Mail-Adresse wird nicht für andere Zwecke als den hier beschriebenen Inhalt verwendet.%0A%0AHerzlich,%0AThierry und Marianne";
  const [offset, setOffset] = useState(0);
  const eventsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return (
      () => window.removeEventListener('scroll', handleScroll)
    );
  }, []);


  return (
    <div className={'Events'} ref={eventsRef}>
      <ParsleyImg className={'parsley-img'}
                  style={{transform: `translateY(${getParallaxPos(eventsRef, offset, 6)}px)`}}/>

      <h2>Daten & Zutaten</h2>
      <FisherAnimation/>

      <p>Wegen Corona wird die nächste Tavolata frühestens im Herbst 2021 stattfinden. Informationen über zukünftige
        Daten und Zutaten können <a href={newsletterAbo}>hier</a> abonniert werden.</p>
    </div>
  );
};
