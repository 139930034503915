import React, {useEffect, useState} from 'react';
import './App.scss';
import {Header} from './client/components/Header';
import {Content} from './client/container/Content';
import {Introduction} from './client/components/Introduction';
import {Events} from './client/components/Events';
import {Footer} from './client/components/Footer';

function App() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return (() => {
      window.removeEventListener('scroll', handleScroll);
    });
  }, []);

  return (
    <div className="App">
      <Header offset={offset}/>

      <Content>
        <Introduction/>
        <Events/>
        <Footer/>
      </Content>
    </div>
  );
}

export default App;
